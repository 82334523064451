import * as React from 'react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';

import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';
import NotFoundLinks from '@/components/NotFoundLinks';
import useSEO from '@/hooks/useSEO';
import useStore from '@/hooks/useStore';
import { getServerSidePropsWithGlobalData } from '@/lib/getServerSideProps';
import { prefetchSEO } from '@/lib/seo';
import { withUrql } from '@/lib/urqlClient';

import styles from './styles.module.scss';

interface Props {
  statusCode: number;
  message?: string;
  err?: any;
}

function Error({ statusCode }: Props) {
  if (statusCode === 404) return <NotFoundPage />;
  return (
    <div className={styles.errorContainer}>
      <h1>{statusCode}</h1>
      <h2 style={{ textAlign: 'center' }}>Something Went Wrong</h2>
      <br />
    </div>
  );
}

function NotFoundPage() {
  const [seoHead, seoScripts] = useSEO('/404');
  const globals = useStore('globals');

  return (
    <>
      {seoScripts}
      <Head>{seoHead}</Head>
      <Header />
      <div data-testid="not-found-page" className={styles.notFoundPage}>
        <div className={styles.banner}>
          <div className={styles.bannerContent}>
            <div
              className={styles.notfoundText}
              dangerouslySetInnerHTML={{ __html: globals.notFoundPage.notFoundHeader }}
            />
            <NotFoundLinks />
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async (ctx) =>
  await getServerSidePropsWithGlobalData(ctx, async (context) => {
    const { res } = ctx;
    const statusCode = res ? res.statusCode : 520;
    if (statusCode === 404) {
      await prefetchSEO(context, '/404');
    }

    return { props: { statusCode } };
  });

export { Error as ErrorPage };
export default withUrql(Error);
