export function allowSearch(search: string) {
  if (!search) {
    return false;
  }

  const lowerCase = search.trim().toLowerCase();

  if (lowerCase.length >= 3) {
    return true;
  }

  const whitelist = ['mg', 'hs', 'ev', 'zs'];
  if (whitelist.some((item) => lowerCase.includes(item))) {
    return true;
  }

  return false;
}
