import Log from '@/lib/Log';
import { Filter } from '@/models';

interface Params {
  filter?: Array<Filter>;
  weight?: Array<Filter>;
  limit: number;
  offset: number;
  sort?: string;
}

export default class StockService {
  static async getFilterOptions(filters: Array<Filter> = []) {
    try {
      const response = await fetch(`/api/stock/filters.json?filter=${StockService.filterQuery(filters)}`);
      return await response.json();
    } catch (error) {
      Log.exception(error, { filters });
      return {};
    }
  }

  static async getModelOptions(makes: Array<string>) {
    const queryString = `makes=${makes.join('|')}`;
    try {
      const response = await fetch(`/api/search/models.json?${queryString}`);
      return await response.json();
    } catch (error) {
      Log.exception(error, { makes });
      return {};
    }
  }

  static async getVariantOptions(modelFilters: Array<Filter>) {
    const modelQuery = modelFilters.map((_f) => `${_f.extra.make}:${_f.extra.model}`).join('|');
    const queryString = `models=${modelQuery}`;
    try {
      const response = await fetch(`/api/search/variants.json?${queryString}`);
      return await response.json();
    } catch (error) {
      Log.exception(error, { modelFilters });
      return {};
    }
  }

  static async loadStockList(params: Params): Promise<Omit<StockListFragment, '__typename'>> {
    try {
      const queryParams = {
        ...params,
        filter: StockService.filterQuery(params.filter),
        weight: StockService.filterQuery(params.weight),
      };
      const queryString = Object.entries(queryParams)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');
      const response = await fetch(`/api/stock.json?${queryString}`);
      return response.json();
    } catch (error) {
      Log.exception(error, { params });
      return {
        total: 0,
        stock: [],
      };
    }
  }

  static filterQuery(customFilters: Array<Filter> = []): string {
    return customFilters.map((f: Filter) => f.queryString()).join(',');
  }

  static getNeighbours = async (filters: Filter[], weights: Filter[], sortMethod: string, index: number) => {
    const filterQuery = filters.map((filter) => filter.queryString()).join(',');
    const weightQuery = weights.map((filter) => filter.queryString()).join(',');
    const params = `filter=${filterQuery}&weight=${weightQuery}&sort=${sortMethod}&index=${index}`;
    const query = `/api/stock/neighbours.json?${params}`;

    try {
      const json = await fetch(query).then((response) => response.json());
      if (json.error) {
        Log.error(`Failed to load neighbours: ${json.error}`, { query });
        return [];
      }
      const vehicles: Array<PartialStockItemFragment | null> = json.map((v: Nullable<Record<string, unknown>>) =>
        v === null ? null : v,
      );
      return vehicles;
    } catch (error) {
      Log.exception(error, { filters, weights, sortMethod, index });
      return [];
    }
  };

  static async getVehicle(stockNo?: string): Promise<PartialStockItemFragment | null> {
    if (!stockNo) {
      return null;
    }

    const result = await this.loadStockList({
      filter: [new Filter('stockNo', stockNo, {}, true)],
      limit: 1,
      offset: 0,
    });
    if (result.total) {
      return result.stock[0];
    }

    return null;
  }

  static async autocomplete(query: string) {
    let results: AutoCompleteItem[] = [];

    try {
      results = (await fetch(`/api/search/autocomplete.json?q=${query.trim()}`).then((response) =>
        response.json(),
      )) as AutoCompleteItem[];
    } catch (error) {
      Log.exception(error, { query });
    }
    return results;
  }
}
