import React from 'react';
import cc from 'classcat';
import Autosuggest, { AutosuggestPropsBase, ContainerProps, InputProps } from 'react-autosuggest';
import { autoUpdate, size as floatingSize, offset, useFloating } from '@floating-ui/react';

import Button from '@/components/Button';
import { IconSearch, IconX } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

interface SearchProps<Suggestion>
  extends Omit<
    AutosuggestPropsBase<Suggestion>,
    | 'id'
    | 'theme'
    | 'inputProps'
    | 'renderInputComponent'
    | 'focusInputOnSuggestionClick'
    | 'renderSuggestionsContainer'
  > {
  suggestions: Suggestion[];
  inputValue: string;
  placeholder: string;
  suggestionResultsHeading?: string;
  disabled?: boolean;
  onClearButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onSearchButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onInputKeyPress?: React.KeyboardEventHandler<HTMLElement>;
  onInputChange: InputProps<Suggestion>['onChange'];
  styleSearchOverride?: string;
  styleInputOverride?: string;
}

const SearchBase = <T,>({
  inputValue,
  placeholder,
  suggestionResultsHeading,
  disabled,
  onClearButtonClick,
  onSearchButtonClick,
  onInputKeyPress,
  onInputChange,
  styleSearchOverride = '',
  styleInputOverride = '',
  ...autoSuggestProps
}: SearchProps<T>) => {
  const { x, y, refs, strategy, placement } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: `bottom-start`,
    middleware: [
      floatingSize({
        apply({ availableWidth, availableHeight, elements, rects }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight - 24}px`,
          });
        },
      }),
      offset(8),
    ],
  });

  const renderSuggestionContainer = (
    containerProps: ContainerProps & { ref: (node: HTMLElement | null) => void },
    children: React.ReactNode,
  ) => {
    const { ref, ...restContainerProps } = containerProps;
    const callRef = (node: HTMLElement | null) => {
      if (node !== null) {
        ref(node);
        refs.setFloating(node);
      }
    };
    return (
      <div
        {...restContainerProps}
        className={cc([restContainerProps.className, styles[placement]])}
        ref={callRef}
        style={{
          position: strategy,
          top: y,
          left: x,
        }}
        data-testid="suggestionsContainer"
        aria-label="search results"
      >
        <div className={styles.suggestionItems}>
          {suggestionResultsHeading && (
            <div className={cc([styles.suggestion, styles.resultsHeading])} data-disabled="true">
              {suggestionResultsHeading}
            </div>
          )}
          {children}
        </div>
      </div>
    );
  };

  return (
    <div className={styleSearchOverride || styles.search} ref={refs.setReference}>
      <Autosuggest<T>
        {...autoSuggestProps}
        id="search"
        theme={styles}
        focusInputOnSuggestionClick={false}
        renderSuggestionsContainer={({ containerProps, children }) =>
          renderSuggestionContainer(containerProps, children)
        }
        inputProps={{
          placeholder: placeholder,
          value: inputValue,
          maxLength: 64,
          disabled: disabled,
          onChange: onInputChange,
          onKeyPress: onInputKeyPress,
          ref: refs.setReference,
        }}
        renderInputComponent={(inputProps) => (
          <div className={styleInputOverride || styles.inputWrapper}>
            <input {...inputProps} />
            {inputProps.value.length > 0 && (
              <button onClick={onClearButtonClick} className={styles.clearButton}>
                <IconX height={24} width={24} />
              </button>
            )}
          </div>
        )}
      />
      <Button styleOverride={styles.searchButton} disabled={false} onClick={onSearchButtonClick} aria-label="Search">
        <IconSearch width={24} height={24} />
      </Button>
    </div>
  );
};

export default SearchBase;
