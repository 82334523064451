/**
 * Find chains of alternating correct and incorrect letters between two words
 * This allows users to misspell and miss letters and still allow accurate highlighting
 * If a user misses a letter when spelling a word, the highlight will indicate that by alternating chains
 * This function can be used to bold correct letter chains
 * @param actualWord | The actual correct spelling of a word
 * @param enteredWord | The entered spelling
 * @return chains of alternating correct and in correct substrings
 */
const SearchWordHighlight = (actualWord: string, enteredWord: string) => {
  const chains: string[] = [];
  let startingIndex = -1;
  let currentChain = '';
  let i = 0;
  let j = 0;

  const addToChain = (chainToAdd: string) => {
    if (!!chainToAdd) {
      chains.push(chainToAdd);
      currentChain = '';
    }
  };

  while (i < actualWord.length) {
    // Correct letter chain
    while (actualWord[i]?.toLowerCase() === enteredWord[j]?.toLowerCase()) {
      if (startingIndex === -1) {
        startingIndex = 0;
      }

      currentChain += actualWord[i++];
      j++;
      if (i === actualWord.length) break;
    }

    addToChain(currentChain);

    // Incorrect letter chain
    while (actualWord[i]?.toLowerCase() !== enteredWord[j]?.toLowerCase() && i < actualWord.length) {
      if (startingIndex === -1) {
        startingIndex = 1;
      }

      currentChain += actualWord[i++];
    }

    addToChain(currentChain);
  }

  return { chains, startingIndex };
};

export default SearchWordHighlight;
