import React, { useId } from 'react';
import { observer } from 'mobx-react';

import useStore from '@/hooks/useStore';
import { textFor, urlFor } from '@/lib/Link';

import styles from './styles.module.scss';

interface Props {
  heading?: string;
}
const NotFoundLinks = ({ heading = 'Helpful links' }: Props) => {
  const {
    notFoundPage: { linkField },
  } = useStore('globals');
  const uid = useId();

  if (!linkField.length) return null;

  return (
    <div className={styles.notFoundLinks} data-testid="notFoundLinks">
      <h3>{heading}</h3>
      <ul>
        {linkField.map((link, i) => (
          <li key={`${uid}-${i}`}>
            <a href={urlFor(link)}>{textFor(link)}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(NotFoundLinks);
